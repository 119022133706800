/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/

.widget {
	margin-bottom: 40px;
	padding: 35px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	display: inline-block;
	width: 100%;
	float: left;
	position: relative;
	background: var(--global-body-bgcolor);
}

.widget:last-child {
	margin-bottom: 0;
}

.widget.widget_rss.text-left span {
	align-items: center;
	display: flex;
}

.widget.widget_rss.text-left span .rsswidget {
	line-height: 0;
	display: inline-block;
	margin-right: 0.313em;
	color: var(--title-color);
}

.widget.widget_rss.text-left span:before {
	top: -0.2em;
}

.widget ul {
	padding: 0;
	margin: 0;
}

.widget ul li {
	padding-bottom: 0.938em;
	list-style: none;
	margin-bottom: 0.938em;
	border-bottom: 0.063rem solid rgb(82 95 129 / 20%);
}

.widget ul li a {
	position: relative;
	color: var(--tertiry-color);
	-ms-word-wrap: break-word;
	word-wrap: break-word;
	padding-left: 16px;
}

.widget ul li a,
ul.wp-block-archives-list li a {
	position: relative;
	display: inline-block;
}

.widget_categories a:hover,
.widget_categories ul li a:hover,
.widget_pages ul li a:hover,
.widget.widget_archive ul li a:hover {
	color: var(--primary-color);
}

.posted-on svg,
.posted-by svg {
	color: var(--primary-color);
}

.css_prefix-blog-meta ul li.widget_categories a,
.css_prefix-blog-meta .widget_categories ul li a {
	padding: 0.188em 0.625em;
	font-size: var(--font-size-small);
	line-height: var(--font-line-height-body);
	letter-spacing: var(--letter-spacing-one);
	text-transform: uppercase;
	color: var(--title-color);
	background: var(--color-categoey-bg);
}

.css_prefix-blog-meta ul li.widget_categories a:hover,
.css_prefix-blog-meta .widget_categories ul li a:hover {
	background: var(--primary-color);
	color: var(--white-color);
}

.widget ul li a:hover {
	color: var(--primary-color);
}

.widget_categories ul li,
.widget_archive ul li,
.widget_pages ul li {
	color: var(--tertiry-color);
	text-transform: capitalize;
	margin-bottom: 0.938em;
	border-bottom: 0.063rem solid rgb(82 95 129 / 20%);
	position: relative;
}

.widget.widget_block .wp-block-tag-cloud a {
	margin: 0 15px 15px 0;
}

.widget.widget_block .wp-block-tag-cloud a:last-child {
	margin: 0;
}

.widget_tag_cloud ul li,
footer ul.wp-tag-cloud li {
	padding: 0;
	margin: 0 15px 15px 0;
	display: inline-block;
	border: none;
}

.widget_tag_cloud ul li:last-child,
footer ul.wp-tag-cloud li:last-child {
	margin: 0;
}

.widget ul li:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border: none;
}

.widget ul ul.children {
	padding-left: 1.563em;
}

.widget ul.menu li {
	border: none;
}

.widget_categories ul ul.children,
.widget_pages ul ul.children {
	padding-top: 0.938em;
}

.widget.widget_nav_menu ul li a,
.widget ul.menu li a {
	padding: 0.625em 0 0.625em 0;
	display: inline-block;
	width: 100%;
	border-bottom: 0.063rem solid var(--border-color-light);
}

.widget_nav_menu .menu .menu-item .toggledrop {
	display: none;
}

.widget.widget_nav_menu ul li,
.widget ul.menu li {
	margin-bottom: 0;
	padding: 0;
}

.wp-block-search .wp-block-search__label {
	position: relative;
	margin-bottom: 1.25em;
	margin-top: 0;
	font-size: 1.5em;
	color: var(--title-color);
	line-height: var(--font-line-height-h5);
	letter-spacing: var(--font-letter-spacing-h5);
	font-weight: var(--font-weight-h5);
	font-family: var(--highlight-font-family);
}

.widget .css_prefix-side-area-title,
.widget .footer-title,
.widget .widget-title,
.widget_block h1,
.widget_block h2,
.widget_block h3,
.widget_block h4,
.widget_block h5 {
	position: relative;
	margin-bottom: 20px;
	margin-top: 0;
	font-size: 22px;
}

/*widget-menu*/
.widget ul {
	padding: 0;
	margin: 0;
}

.widget ul li {

	list-style: none;
}

.widget ul li:last-child {
	margin-bottom: 0;
}

.widget ul ul.children {
	padding-left: 25px;
}

.widget ul li a:hover {
	text-decoration: none;
}

.widget ul li a:before {
	content: "\f105";
	position: absolute;
	top: 50%;
	margin-top: 0;
	font-family: 'Font Awesome 5 Free';
	font-size: 16px;
	font-weight: 700;
	left: 0px;
	line-height: 0;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.widget ul li a:hover:before {
	color: var(--priamry-color);
}


/* Meta - widget RSS*/
.widget.widget_rss ul li {
	margin-bottom: 20px;
}

.widget.widget_rss ul li:last-child {
	margin-bottom: 0;
}

.widget.widget_rss ul li a {
	padding-left: 0;
	font-size: 16px;
	color: var(--title-color);
	font-weight: 600;
}

.widget.widget_rss ul li a:hover {
	color: var(--primary-color);
}

.widget.widget_rss ul li a:before {
	display: none;
}

.widget.widget_rss ul li cite {
	font-weight: 600;
	margin-top: 5px;
	display: inline-block;
	width: 100%;
}

.widget.widget_rss ul li .rss-date {
	text-transform: uppercase;
	display: inline-block;
	width: 100%;
	margin: 5px 0;
	color: var(--secondary-color);
	font-size: 14px;
}

/*widget Nav Menu*/
.widget.widget_nav_menu ul li,
.widget ul.menu li {
	margin-bottom: 0;
}

.widget.widget_nav_menu ul li .sub-menu,
.widget ul.menu li .sub-menu {
	display: block !important;
	padding-left: 20px;
}

.widget.widget_nav_menu ul li a,
.widget ul.menu li a {
	padding: 10px 0 10px 15px;
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid #eeeeee;
}

.widget.widget_nav_menu ul li i,
.widget ul.menu li i {
	display: none;
}

article.hentry .iq-blog-box .iq-blog-detail .blog-content .widget_tag_cloud ul li a:hover {
	color: var(--white-color);
}

ol.wp-block-latest-comments {
	padding-left: 0;
}

.wp-block-latest-comments__comment {
	display: block;
}

.wp-block-latest-comments__comment-meta {
	color: var(--primary-color);
	background: none;
}

.wp-block-latest-comments__comment-meta a.wp-block-latest-comments__comment-author {
	color: var(--title-color);
}

.wp-block-latest-comments__comment-meta a {
	color: var(--tertiry-color);
	line-height: var(--global-font-line-height);
}

.has-dates .wp-block-latest-comments__comment:last-child,
.has-excerpts .wp-block-latest-comments__comment:last-child {
	margin-bottom: 0;
}

.wp-block-latest-comments__comment-excerpt p {
	margin: 0;
}

/* widget categoies */
.ul.wp-block-categories li {
	padding-bottom: 0;
}

.widget_categories ul li a,
ul.wp-block-categories li a,
.widget_meta ul li a,
ul.wp-block-meta li a,
.widget_archive ul li a,
ul.wp-block-archives-list li a,
.widget_pages li a,
.widget.widget_nav_menu ul li a,
.widget ul.menu li a {
	font-size: var(--font-size-xs);
	letter-spacing: var(--letter-spacing-two);
	text-transform: uppercase;
	font-weight: var(--font-weight-bold);
}

.widget_categories ul li a, ul.wp-block-categories li a {
	color: var(--tertiry-color);
	padding: 0px 0px 0px 15px;
	border-radius: 5px;
}

.widget_categories ul li a:hover, ul.wp-block-categories li a:hover {
	color: var(--primary-color);
}

/*===========
service-menu
=================*/
.widget .menu-service-menu-container ul.menu li {
	margin-bottom: 16px;
}

.widget .menu-service-menu-container ul.menu li:last-child {
	margin-bottom: 0;
}

.widget .menu-service-menu-container ul.menu li a {
	padding: 16px 20px;
	background: var(--white-color);
	color: var(--title-color);
	font-family: var(--highlight-font-family);
	font-weight: var(--font-weight-bold);
	font-size: var(--font-szie-body);
	line-height: var(--font-line-height-body);
	border-bottom: none;
	text-transform: capitalize;
	-webkit-border-radius: var(--border-radius-box);
	border-radius: var(--border-radius-box);
	letter-spacing: 0;
	overflow: hidden;
}

.widget .menu-service-menu-container ul.menu li a:hover,
.widget .menu-service-menu-container ul.menu li.current-menu-item a {
	color: var(--white-color);
	background: var(--primary-color);
}

.widget .menu-service-menu-container ul.menu li.current-menu-item a,
.widget .menu-service-menu-container ul.menu li a:hover {
	padding: 16px 20px 16px 45px;
}

.widget .menu-service-menu-container ul.menu li a::before {
	color: var(--white-color);
	opacity: 0;
	visibility: hidden;
	transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
}

.widget .menu-service-menu-container ul.menu li a:hover::before,
.widget .menu-service-menu-container ul.menu li.current-menu-item a::before {
	left: 22px;
	opacity: 1;
	visibility: visible;
}

@media(max-width:29.938em) {
	.widget .widget-title .title-border {
		margin: 0 0 0 0.625em;
	}

	.widget ul ul.children {
		padding-left: 0.938em;
	}
}

@media (max-width: 1199px) {
	.widget.widget_nav_menu ul li .sub-menu, .widget ul.menu li .sub-menu {
		padding-left: 10px;
	}
}


@media (max-width: 991px) {
	.widget_calendar .calendar_wrap table th, .widget_calendar .calendar_wrap table td {
		padding: 5px;
	}

	.widget_calendar .calendar_wrap table th, .widget_calendar .calendar_wrap table td {
		padding: 2px;
	}
}